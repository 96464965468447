import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Actions from '../../store/redux/actions/index.js';

import Button from '../../components/Button/Button.jsx';
import SubmitButton from '../../components/Button/SubmitButton.jsx';
import CustomFileInput from '../../components/FileInput/FileUpload.jsx';
import Input from '../../components/Input/Input.jsx';
import Toast from '../../components/Popup/Toast.jsx';

import regexValidator from '../../utils/regexValidator.js';

import validationMessage from '../../hooks/useValidationMessage.js';

import { BackGroundColors, ButtonSize, InputSize, InputType, LabelTextType } from '../../constants/ComponentEnums.jsx';

const Feedback = ({ handleModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customFileInputRef = useRef();
  const userInfo = useSelector((state) => state.user.getById);
  const [createData, setCreateData] = useState({});
  const [isRequired, setIsRequired] = useState({ email: true, message: true });
  const [error, setError] = useState({});
  const [requiredError, setRequiredError] = useState({});

  const handleButtonClick = (e) => {
    customFileInputRef.current.handleFileInsert(e);
  };

  useEffect(() => {
    if (userInfo?.email !== undefined) {
      setCreateData({ ...createData, email: userInfo?.email });
      setIsRequired({ ...isRequired, email: false });
    }
  }, [userInfo]);

  const onFocus = (e) => {
    e.preventDefault();
    setRequiredError({});
  };
  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, required } = e.target;
    if (value !== '') {
      setCreateData({ ...createData, [id]: value });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
      setError((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
      setIsRequired({ ...isRequired, [id]: true });
    }
    const validationResult = regexValidator(type, value);
    if (required && !validationResult && value === '') {
      setError({
        ...error,
        [id]: <span className='text-danger'>{t('validationMessages.this_field_is_required')}</span>,
      });
    } else if (required && validationResult && value !== '') {
      setError({ ...error, [id]: undefined });
    }
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: <span className='text-danger'>{t('validationMessages.' + validationMessage(type))}</span>,
      });
    } else {
      setError({ ...error, [id]: undefined });
    }

    if (required && value !== '' && validationResult) {
      setIsRequired({ ...isRequired, [id]: false });
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    if (Object.keys(createData).length > 0 && Object.values(isRequired).every((e) => e === false)) {
      await dispatch(Actions.feedbackActions.createAction(createData));
    } else if (!Object.values(isRequired).every((e) => e === false)) {
      const keys = Object.keys(isRequired).filter((key) => isRequired[key]);

      const updatedErrors = keys.reduce(
        (acc, key) => {
          acc[key] = <span className='text-danger'>{t('validationMessages.this_field_is_required')}</span>;
          return acc;
        },
        { ...requiredError }
      );

      setRequiredError(updatedErrors);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.feedbackActions.cleanState());
    };
  }, []);

  return (
    <div className='container-fluid'>
      <div className='d-flex justify-content-center'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <Input
                id={'email'}
                className={InputSize.Medium}
                type={InputType.Email}
                value={createData.email || ''}
                labelValue={t('feedback.input_email')}
                labelFontType={LabelTextType.Bold}
                placeholder={'name@example.com'}
                onChange={onChangeText}
                onFocus={onFocus}
                error={error.email}
                required
                requiredError={requiredError.email}
              />
            </div>
            <div className='col-12 mt-md-4 py-1'>
              <Input
                id={'message'}
                className={InputSize.Medium}
                inputType={InputType.Text}
                labelValue={t('feedback.message')}
                value={createData.message || ''}
                textArea={{ row: 3, col: 12 }}
                labelFontType={LabelTextType.Bold}
                onChange={onChangeText}
                onFocus={onFocus}
                required
                requiredError={requiredError.message}
              />
            </div>
            <div className='col-12 col-md-6'>
              <CustomFileInput
                id={'image'}
                key={'image'}
                hidden={true}
                removeState={createData.image !== undefined ? false : true}
                fileType={'Image'}
                dataFrom={'Career'}
                changeTxt={true}
                ref={customFileInputRef}
                func={(val) => {
                  setCreateData(
                    Object.assign({}, createData, {
                      image: val.base64String,
                      imgExtension: val.extension,
                      url: val.fileName,
                    })
                  );
                }}
              />
            </div>
          </div>
          {createData?.image !== undefined && (
            <div className='d-flex justify-content-between text-custom fw-bold mb-2'>
              <label> {t('feedback.image_info', { count: 1 })}</label>
              <FontAwesomeIcon
                role='button'
                onClick={() => {
                  setCreateData((prevState) => {
                    const { image, imgExtension, url, ...rest } = prevState;
                    return rest;
                  });
                }}
                icon={faTrashCan}
              />
            </div>
          )}
          <div className='d-md-flex justify-content-md-between mb-2'>
            <div className='col-12 col-md-6 me-md-1 me-0'>
              <Button
                size={ButtonSize.Large}
                type={BackGroundColors.Custom}
                outline
                text={t('feedback.upload_image_button')}
                onClick={handleButtonClick}
              />
            </div>
            <div className='col-12 col-md-6 mt-2 mt-md-0'>
              <SubmitButton stateName={'feedback'} onClick={onSave} />
            </div>
          </div>
        </div>
      </div>
      <Toast
        id={'feedback'}
        key={'feedback'}
        stateName={'feedback'}
        datalayer={'feedback_form'}
        onClose={(val) => {
          if (val) {
            setCreateData({});
            handleModal(false);
          }
        }}
      />
    </div>
  );
};
export default Feedback;
