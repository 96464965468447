import React from 'react';

const UI = {
  Subscriptions: React.lazy(() => import('./SubscriptionPages/Subscriptions.jsx')),
  Support: React.lazy(() => import('./CommonPages/Support.jsx')),
  ProfileSettings: React.lazy(() => import('./UserProfilePages/ProfileSettings.jsx')),
  Privacy: React.lazy(() => import('./CommonPages/Privacy.jsx')),
  GiftCode: React.lazy(() => import('./UserProfilePages/GiftCode.jsx')),
  PersonalInformation: React.lazy(() => import('./UserProfilePages/PersonalInformation.jsx')),
  PrivatePackage: React.lazy(() => import('./SubscriptionPages/PrivatePackage.jsx')),
  GroupPackage: React.lazy(() => import('./SubscriptionPages/GroupPackage.jsx')),
  MySessionsHistory: React.lazy(() => import('./AttendeePages/MySessionsHistory.jsx')),
  MySessions: React.lazy(() => import('./AttendeePages/MySessions.jsx')),
  Dashboard: React.lazy(() => import('./Dashboard/Dashboard.jsx')),
  MyOrders: React.lazy(() => import('./OrderPages/MyOrders.jsx')),
  Calendar: React.lazy(() => import('./AttendeePages/Calendar.jsx')),
  TermsOFUse: React.lazy(() => import('./CommonPages/TermsOFUse.jsx')),
  Mentors: React.lazy(() => import('./MentorPages/Mentors.jsx')),
  MentorDetails: React.lazy(() => import('./MentorPages/MentorDetails.jsx')),
  OrderBasket: React.lazy(() => import('./OrderPages/OrderBasket.jsx')),
  Buy: React.lazy(() => import('./OrderPages/Buy.jsx')),
  ChangePassword: React.lazy(() => import('./UserProfilePages/ChangePassword.jsx')),
  Schedule: React.lazy(() => import('./AttendeePages/Schedule.jsx')),
  Congratulations: React.lazy(() => import('./AttendeePages/Congratulations.jsx')),
  Result3D: React.lazy(() => import('./OrderPages/Payment3DResult.jsx')),
  Callback: React.lazy(() => import('./OrderPages/Callback.jsx')),
  PaymentPolicy: React.lazy(() => import('./CommonPages/PaymentPolicy.jsx')),
  MembershipPolicy: React.lazy(() => import('./CommonPages/MembershipPolicy.jsx')),
  CookiePolicy: React.lazy(() => import('./CommonPages/CookiePolicy.jsx')),
  Tiktok: React.lazy(() => import('./CommonPages/Tiktok.jsx')),
};

export default UI;
