import React from 'react';

const Landing = {
  Main: React.lazy(() => import('./CommonPages/Main.jsx')),
  Register: React.lazy(() => import('./AuthPages/Register.jsx')),
  Login: React.lazy(() => import('./AuthPages/Login.jsx')),
  Unauthorized: React.lazy(() => import('./CommonPages/Unauthorized.jsx')),
  Error404: React.lazy(() => import('./CommonPages/Error404.jsx')),
  CompanyForm: React.lazy(() => import('./CompanyForm.jsx')),
  BecomeASpeaker: React.lazy(() => import('./BecomeASpeaker.jsx')),
  Faq: React.lazy(() => import('./Faq.jsx')),
  OnBoarding: React.lazy(() => import('./OnBoarding/OnBoarding.jsx')),
  Online: React.lazy(() => import('./CommonPages/MainSubPages/Online.jsx')),
  Cafe: React.lazy(() => import('./CommonPages/MainSubPages/Cafe.jsx')),
  Kids: React.lazy(() => import('./CommonPages/MainSubPages/Kids.jsx')),
  Company: React.lazy(() => import('./CommonPages/MainSubPages/Company.jsx')),
  Kadikoy: React.lazy(() => import('./CommonPages/MainSubPages/Branches/Kadikoy.jsx')),
  Antalya: React.lazy(() => import('./CommonPages/MainSubPages/Branches/Antalya.jsx')),
  Besiktas: React.lazy(() => import('./CommonPages/MainSubPages/Branches/Besiktas.jsx')),
  Camlica: React.lazy(() => import('./CommonPages/MainSubPages/Branches/Camlica.jsx')),
  Mecidiyekoy: React.lazy(() => import('./CommonPages/MainSubPages/Branches/Mecidiyekoy.jsx')),
  Campaigns: React.lazy(() => import('./CampaignPages/Campaign.jsx')),
  CampaignDetail: React.lazy(() => import('./CampaignPages/CampaignDetail.jsx')),
  ForgotPassword: React.lazy(() => import('./AuthPages/ForgotPassword.jsx')),
  VerifyEmail: React.lazy(() => import('./AuthPages/VerifyEmail.jsx')),
  LeadForm: React.lazy(() => import('./LeadForm.jsx')),
  LandingPages: React.lazy(() => import('./CommonPages/LandingPages.jsx')),
  BringYourFriend: React.lazy(() => import('./CommonPages/BringYourFriend.jsx')),
  WheelOfFortune: React.lazy(() => import('./WheelOfFortune.jsx')),
  DownloadPage: React.lazy(() => import('./QRDownload.jsx')),
  VideoAsk: React.lazy(() => import('./VideoAsk.jsx')),
  Sitemap: React.lazy(() => import('./Sitemap.jsx')),
};

export default Landing;
