import React from 'react';

const BackOffice = {
  CareerList: React.lazy(() => import('./Career/List.jsx')),
  SpeakerList: React.lazy(() => import('./Speaker/List.jsx')),
  SpeakerCreate: React.lazy(() => import('./Speaker/Create.jsx')),
  SpeakerUpdate: React.lazy(() => import('./Speaker/Update.jsx')),
  SpeakerEffortReport: React.lazy(() => import('./Speaker/SpeakerEffortReport.jsx')),
  SpeakerEffortDetails: React.lazy(() => import('./Speaker/SpeakerEffortDetails.jsx')),
  AvailabilityCalendar: React.lazy(() => import('./Speaker/SpeakerAvability/AvailabilityCalendar.jsx')),
  AttendeeList: React.lazy(() => import('./Attendee/List.jsx')),
  AttendeeCreate: React.lazy(() => import('./Attendee/Create.jsx')),
  AttendeeUpdate: React.lazy(() => import('./Attendee/Update.jsx')),
  ManagerList: React.lazy(() => import('./Manager/List.jsx')),
  ManagerCreate: React.lazy(() => import('./Manager/Create.jsx')),
  ManagerUpdate: React.lazy(() => import('./Manager/Update.jsx')),
  MeetingSchemaList: React.lazy(() => import('./MeetingSchema/List.jsx')),
  MeetingSchemaCreate: React.lazy(() => import('./MeetingSchema/Create.jsx')),
  MeetingSchemaUpdate: React.lazy(() => import('./MeetingSchema/Update.jsx')),
  OrderList: React.lazy(() => import('./Order/List.jsx')),
  OrderCreate: React.lazy(() => import('./Order/Create.jsx')),
  OrderUpdate: React.lazy(() => import('./Order/Update.jsx')),
  EftPayments: React.lazy(() => import('./Order/EftControl.jsx')),
  Payments: React.lazy(() => import('./Order/Payments.jsx')),
  BranchHolidayCreate: React.lazy(() => import('./BranchHoliday/Create.jsx')),
  BranchHolidayList: React.lazy(() => import('./BranchHoliday/List.jsx')),
  BranchHolidayUpdate: React.lazy(() => import('./BranchHoliday/Update.jsx')),
  CallThem: React.lazy(() => import('./CallThem.jsx')),
  AgeIntervalList: React.lazy(() => import('./PreferencePages/AgeInterval/List.jsx')),
  AgeIntervalCreate: React.lazy(() => import('./PreferencePages/AgeInterval/Create.jsx')),
  AgeIntervalUpdate: React.lazy(() => import('./PreferencePages/AgeInterval/Update.jsx')),
  LocationList: React.lazy(() => import('./PreferencePages/Location/List.jsx')),
  LocationCreate: React.lazy(() => import('./PreferencePages/Location/Create.jsx')),
  LocationUpdate: React.lazy(() => import('./PreferencePages/Location/Update.jsx')),
  SessionCategoryList: React.lazy(() => import('./PreferencePages/SessionCategory/List.jsx')),
  SessionCategoryCreate: React.lazy(() => import('./PreferencePages/SessionCategory/Create.jsx')),
  SessionCategoryUpdate: React.lazy(() => import('./PreferencePages/SessionCategory/Update.jsx')),
  SessionPackageList: React.lazy(() => import('./PreferencePages/SessionPackage/List.jsx')),
  SessionPackageCreate: React.lazy(() => import('./PreferencePages/SessionPackage/Create.jsx')),
  SessionPackageUpdate: React.lazy(() => import('./PreferencePages/SessionPackage/Update.jsx')),
  TimesPerMonthList: React.lazy(() => import('./PreferencePages/TimesPerMonth/List.jsx')),
  TimesPerMonthCreate: React.lazy(() => import('./PreferencePages/TimesPerMonth/Create.jsx')),
  TimesPerMonthUpdate: React.lazy(() => import('./PreferencePages/TimesPerMonth/Update.jsx')),
  TimesPerWeekList: React.lazy(() => import('./PreferencePages/TimesPerWeek/List.jsx')),
  TimesPerWeekCreate: React.lazy(() => import('./PreferencePages/TimesPerWeek/Create.jsx')),
  TimesPerWeekUpdate: React.lazy(() => import('./PreferencePages/TimesPerWeek/Update.jsx')),
  ZoneList: React.lazy(() => import('./PreferencePages/Zone/List.jsx')),
  ZoneCreate: React.lazy(() => import('./PreferencePages/Zone/Create.jsx')),
  ZoneUpdate: React.lazy(() => import('./PreferencePages/Zone/Update.jsx')),
  ScheduleManager: React.lazy(() => import('./Operation/ScheduleManager.jsx')),
  OperationManagment: React.lazy(() => import('./Operation/OperationManagment.jsx')),
  ScheduleMeetings: React.lazy(() => import('./Operation/SchedulePlan.jsx')),
  BannerCreate: React.lazy(() => import('./Banner/Create.jsx')),
  BannerList: React.lazy(() => import('./Banner/List.jsx')),
  BannerUpdate: React.lazy(() => import('./Banner/Update.jsx')),
  MeetingListByAttendee: React.lazy(() => import('./Meetings/List.jsx')),
  MeetingListBySchema: React.lazy(() => import('./Meetings/ListBySchema.jsx')),
  FeedbackList: React.lazy(() => import('./Feedback/List.jsx')),
  OrderItemList: React.lazy(() => import('./Order/OrderItem/List.jsx')),
  OrderItemUpdate: React.lazy(() => import('./Order/OrderItem/Update.jsx')),
  CreatedTodayTrials: React.lazy(() => import('./Order/FreeTrial/CreatedToday.jsx')),
  UpcomingTrials: React.lazy(() => import('./Order/FreeTrial/Upcoming.jsx')),
  CouponCreate: React.lazy(() => import('./CouponCodes/Create.jsx')),
  CouponList: React.lazy(() => import('./CouponCodes/List.jsx')),
  CouponUpdate: React.lazy(() => import('./CouponCodes/Update.jsx')),
  LeadList: React.lazy(() => import('./Lead/List.jsx')),
  LeadUpdate: React.lazy(() => import('./Lead/Update.jsx')),
  RenewOrderItem: React.lazy(() => import('./Order/OrderItem/RenewOrderItem.jsx')),
  RemainingOrderCalculation: React.lazy(() => import('./Order/RemainingOrderCalculation.jsx')),
  CompanyList: React.lazy(() => import('./Company/List.jsx')),
  CompanyCreate: React.lazy(() => import('./Company/Create.jsx')),
  CompanyUpdate: React.lazy(() => import('./Company/Update.jsx')),
  CompanyOrderList: React.lazy(() => import('./Company/CompanyOrder/List.jsx')),
  CompanyOrderCreate: React.lazy(() => import('./Company/CompanyOrder/Create.jsx')),
  CompanyOrderUpdate: React.lazy(() => import('./Company/CompanyOrder/Update.jsx')),
  AttendeeOrderHistory: React.lazy(() => import('./Attendee/AttendeeOrderDetails.jsx')),
  CampaignList: React.lazy(() => import('./Campaign/List.jsx')),
  CampaignCreate: React.lazy(() => import('./Campaign/Create.jsx')),
  CampaignUpdate: React.lazy(() => import('./Campaign/Update.jsx')),
  RepotingList: React.lazy(() => import('./Reporting/List.jsx')),
  CompanyReporting: React.lazy(() => import('./Company/Reporting.jsx')),
  MergeCSV: React.lazy(() => import('./MergeCsv.jsx')),
  TestRedate: React.lazy(() => import('./TestRedate.jsx')),
  TestPrice: React.lazy(() => import('./TestPrice.jsx')),
  SegmentEditor: React.lazy(() => import('./Notifications/SegmentEditor.jsx')),
  CompanyFormList: React.lazy(() => import('./CompanyForm/List.jsx')),
  Packages: React.lazy(() => import('./Packages.jsx')),
};

export default BackOffice;
