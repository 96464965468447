import React, { Suspense } from 'react';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import 'core-js/stable/index.js';
import 'regenerator-runtime/runtime.js';

import Actions from './store/redux/actions/index.js';
import { store } from './store/redux/store.js';

import { Loader } from './components/Loader/Loader.jsx';

import App from './app.js';
import { storageKeys } from './constants/Enum.jsx';
import './i18n.js';

// const App = lazy(() => import('./App')); TODO How it works?

const MainApp = () => {
  var userLang = navigator.language || navigator.userLanguage;
  const localStorageLang = localStorage.getItem(storageKeys.languge);
  if (!localStorageLang) {
    localStorage.setItem(storageKeys.languge, userLang);
  }

  const currentZoneSessionStorage = sessionStorage.getItem(storageKeys.timeZone);
  const currentZoneLocalStorage = localStorage.getItem(storageKeys.timeZone);
  const parsedZone = JSON.parse(currentZoneLocalStorage);
  const storedTimeZone = parsedZone?.timeZone;

  const fetchLocationByIP = async () => {
    try {
      const response = await fetch(`https://ipwhois.app/json/`);
      const data = await response.json();
      const countryCode = data.country_code;
      const country = data.country;
      const timezone = data.timezone;
      sessionStorage.setItem(storageKeys.timeZone, timezone);

      if (country === 'United Arab Emirates' || countryCode === 'AE' || countryCode === 'DE') {
        sessionStorage.setItem('wheelAble', 'true');
        localStorage.setItem('wheelAble', 'true');
        document.cookie = 'wheelAble=true; path=/;';
      }

      if (timezone !== storedTimeZone) {
        store.dispatch(Actions.userActions.getCurrenyByIPAddressAction());
      }
    } catch (error) {
      store.dispatch(Actions.userActions.getCurrenyByIPAddressAction());
    }
  };

  if (storedTimeZone === undefined) {
    // fetchLocationByIP();
    store.dispatch(Actions.userActions.getCurrenyByIPAddressAction());
  }

  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<MainApp />);
